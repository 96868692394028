
let Client = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    /**
     * ��ȡ���пͻ���
     * @param {any} success �����ɹ��ص�����
     * @param {any} error ����ʧ�ܻص�����
     */
    this.Clients = function (keyWord,productId , organizationId ,success, error) {
        var url = ServiceBaseAddress + `/api/Client/All/${productId}/${organizationId}`;
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * ��ȡָ����֯����ע��Ŀͻ���
     * @param {any} organizationId
     * @param {any} success �����ɹ��ص�����
     * @param {any} error ����ʧ�ܻص�����
     */
    this.ClientsByOrganization = function (organizationId, success, error) {
        var url = ServiceBaseAddress + '/api/Client/Organization/' + organizationId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * ���ӿͻ���
     * @param {any} key
     * @param {any} name �ͻ�������
     * @param {any} description �ͻ��˼��
     * @param {any} organizationId ��������
     * @param {any} success �����ɹ��ص�����
     * @param {any} error ����ʧ�ܻص�����
     */

    this.Add = function (key, name, description, organizationId, productId,statu,success, error) {
        var url = ServiceBaseAddress + '/api/Client';
        var param = {
            key: key,
            name: name,
            description: description,
            organizationId: organizationId,
            productId: productId,
            statu:statu
        };
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * �༭�ͻ���
     * @param {any} clientId
     * @param {any} key
     * @param {any} name
     * @param {any} description
     * @param {any} organizationId
     * @param {any} success �����ɹ��ص�����
     * @param {any} error ����ʧ�ܻص�����
     */
    this.Edit = function (clientId, key, name, description, organizationId, productId,statu, success, error) {
        var url = ServiceBaseAddress + '/api/Client';
        var param = {
            id: clientId,
            key: key,
            name: name,
            description: description,
            organizationId: organizationId,
            productId: productId,
            statu:statu
        };
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * ɾ���ͻ���
     * @param {any} clientId
     * @param {any} success �����ɹ��ص�����
     * @param {any} error ����ʧ�ܻص�����
     */
    this.Remove = function (clientId, success, error) {
        var url = ServiceBaseAddress + '/api/Client/' + clientId;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * ˢ�¿ͻ�����Կ
     * @param {any} clientId
     * @param {any} success �����ɹ��ص�����
     * @param {any} error ����ʧ�ܻص�����
     */
    this.RefreshSecret = function (clientId, success, error) {
        var url = ServiceBaseAddress + '/api/Client/RefreshSecret/' + clientId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * ��ȡָ���ͻ����õ���ϵͳ
     * @param {any} clientId
     * @param {any} success �����ɹ��ص�����
     * @param {any} error ����ʧ�ܻص�����
     */
    this.Subsystems = function (clientId, success, error) {
        var url = ServiceBaseAddress + '/api/Client/' + clientId + '/Subsystem';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * ���ÿͻ��˿��õ���ϵͳ
     * @param {any} clientId
     * @param {any} subsystemIds
     * @param {any} success �����ɹ��ص�����
     * @param {any} error ����ʧ�ܻص�����
     */
    this.SetSubsystem = function (clientId, subsystemIds, success, error) {
        var url = ServiceBaseAddress + '/api/Client/' + clientId + '/Subsystem';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, subsystemIds,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.SetSetLimit = function (subsystemIds, success, error) {
        var url = ServiceBaseAddress + '/api/Client/SetLimit';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, subsystemIds,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }


}

export { Client }
