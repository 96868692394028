<template>
  <div class="height1">
    <!--      头部      -->
    <bread></bread>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <!-- <el-form-item style="width:200px">
                                <el-select v-model="searchForm.clientId" placeholder="请选择客户端" size="small"
                                    style="margin-right: 10px" clearable @change="search()">
                                    <el-option v-for="item in clientList" :key="item.key" :label="`${item.name}【${item.productName}】`"
                                        :value="item.key">
                                    </el-option>
                                </el-select>
                            </el-form-item> -->
              <el-form-item style="width: 200px">
                <el-select v-model="searchForm.operation" placeholder="请选择操作" size="small" style="margin-right: 10px" clearable @change="search()">
                  <el-option v-for="item in operationsList" :key="item" :label="item" :value="item"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="search()"> </el-date-picker>
              </el-form-item>
              <!-- <el-form-item style="width:120px">
                                <el-input v-model="searchForm.userId" type="text" size="small" clearable
                                    placeholder="用户ID" @keyup.enter.native="search"></el-input>
                            </el-form-item> -->
              <el-form-item style="width: 150px">
                <el-input v-model="searchForm.userAccount" type="text" size="small" clearable placeholder="用户账号" @keyup.enter.native="search"></el-input>
              </el-form-item>
              <el-form-item style="width: 150px">
                <el-input v-model="searchForm.userName" type="text" size="small" clearable placeholder="用户名" @keyup.enter.native="search"></el-input>
              </el-form-item>
              <el-form-item style="width: 150px">
                <el-input v-model="searchForm.keyWord" type="text" size="small" clearable placeholder="备注" @keyup.enter.native="search"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" style="width: 100%" height="calc(100% - 90px)">
          <el-table-column label="编号" type="index"></el-table-column>
          <el-table-column prop="datetime" label="时间" width="150px" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.datetime | timefilters }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作人" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.name }}</p>
              <p>{{ scope.row.account }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="operation" label="操作" align="center">
            <template slot-scope="scope">
              <p>【{{ scope.row.operation }}】</p>
              <p>{{ scope.row.remark }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="message" label="返回信息" align="center" sortable showOverflowTooltip></el-table-column>
          <el-table-column prop="clientID" label="客户端名称" align="center">
            <template slot-scope="scope">
              <!-- <p v-if="clientList.length>0">{{ clientList.filter(i=>i.key==scope.row.clientID)[0].productName}}</p> -->
              <p v-if="clientList.length > 0">{{ clientList.filter((i) => i.key == scope.row.clientID)[0].name }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="ipAddress" label="IP" align="center"> </el-table-column>
          <el-table-column prop="os" label="操作系统" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.os == 'Unknow' ? '未知' : scope.row.os }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="browser" label="浏览器" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.browser == 'Unknow' ? '未知' : scope.row.browser }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="device" label="设备型号" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.device == 'Unknow' ? '未知' : scope.row.device }}</p>
            </template>
          </el-table-column>
          <el-table-column type="expand">
            <template slot-scope="scope">
                <p style="overflow: auto">{{ scope.row.message }}</p>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { User } from '../../../components/HospitalDomain/User'
import { Client } from '../../../components/HospitalDomain/Client'
import { getLastWeekDate, getLastMonthDate, getLastSixMonthDate, getLastOneYearDate } from '../../../components/common/getTime'
export default {
  name: '',
  components: {},
  data() {
    var user = new User(this.TokenClient, this.Services.Authorization)
    var client = new Client(this.TokenClient, this.Services.Authorization)
    return {
      User: user,
      Client: client,
      searchForm: {
        startTime: '',
        endTime: '',
        keyWord: '',
        datepicker: '',
        userId: '',
        userAccount: '',
        userName: '',
        clientId: '',
        operation: '',
      },
      tableData: [],
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      DataTotal: 0,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      clientList: [],
      operationsList: [],
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
    }
  },
  mounted() {
    this.searchForm.datepicker = getLastWeekDate()
    this.getOperations()
    this.LoadClient()
    this.GetList()
  },
  methods: {
    LoadClient() {
      var _this = this
      _this.Client.Clients(
        '',
        0,
        0,
        function (data) {
          _this.clientList = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    getOperations() {
      var _this = this
      _this.User.getOperations(
        function (data) {
          _this.operationsList = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    GetList() {
      var _this = this
      _this.tableData = []
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      _this.User.getUserOperation(
        item.userId,
        item.userAccount,
        item.userName,
        this.ClientId,
        item.operation,
        this.userInfo.organizationId,
        item.keyWord,
        item.startTime,
        item.endTime,
        _this.PageIndex,
        _this.PageSize,
        function (data) {
          _this.tableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.DataTotal = data.data.dataTotal
        },
        function (error) {
          console.log(error)
        }
      )
    },
    search() {
      this.PageIndex = 1
      this.GetList()
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.GetList()
    },
  },
}
</script>

<style scoped lang="scss"></style>
