// 最近1周、最近1月、最近3个月 时分秒为00:00:00
var now = new Date()
now.setTime(now.getTime())
var end = now;
// 今天的时间
// var end = new Date()
var year = end.getFullYear();
var month = end.getMonth() + 1; // 0-11表示 1-12月
var day = end.getDate();
var dateObj = {};
dateObj.start = null;
dateObj.end = year + "-" + month + "-" + day;
var endMonthDay = new Date(year, month, 0).getDate(); // 当前月的总天数

// 获取近一周日期范围
export function getLastWeekDate() {
  if (day - 6 <= 0) {
    // 如果在当月7日之前
    const startMonthDay = new Date(year, parseInt(month) - 1, 0).getDate(); // 1周前所在月的总天数
    if (month - 1 <= 0) {
      // 如果在当年的1月份
      dateObj.start = year - 1 + "-" + 12 + "-" + (31 - (6 - day));
    } else {
      dateObj.start = year + "-" + (month - 1) + "-" + (startMonthDay - (6 - day));
    }
  } else {
    dateObj.start = year + "-" + month + "-" + (day - 6);
  }
  const lastWeekDate = [dateObj.start, dateObj.end];
  return lastWeekDate;
}

// 获取近一个月日期范围
export function getLastMonthDate() {
  if (month - 1 <= 0) {
    // 如果是1月，年数往前推一年<br>
    dateObj.start = year - 1 + "-" + 12 + "-" + day;
  } else {
    const startMonthDay = new Date(year, parseInt(month) - 1, 0).getDate();
    if (startMonthDay < day) {
      // 1个月前所在月的总天数小于现在的天日期
      if (day < endMonthDay) {
        // 当前天日期小于当前月总天数
        dateObj.start = year + "-" + (month - 1) + "-" + (startMonthDay - (endMonthDay - day));
      } else {
        dateObj.start = year + "-" + (month - 1) + "-" + startMonthDay;
      }
    } else {
      dateObj.start = year + "-" + (month - 1) + "-" + day;
    }
  }
  const newMonthDate = [dateObj.start, dateObj.end];
  return newMonthDate;
}

// 获取近六个月日期范围
export function getLastSixMonthDate() {
  if (month - 6 <= 0) {
    // 如果是1、2、3、4、5、6月，年数往前推一年
    const start6MonthDay = new Date(year - 1, 12 - (6 - parseInt(month)), 0).getDate(); // 6个月前所在月的总天数
    if (start6MonthDay < day) {
      // 6个月前所在月的总天数小于现在的天日期
      dateObj.start = year - 1 + "-" + (12 - (6 - month)) + "-" + start6MonthDay;
    } else {
      dateObj.start = year - 1 + "-" + (12 - (6 - month)) + "-" + day;
    }
  } else {
    const start6MonthDay = new Date(year, parseInt(month) - 6, 0).getDate(); // 6个月前所在月的总天数
    if (start6MonthDay < day) {
      // 3个月前所在月的总天数小于现在的天日期
      if (day < endMonthDay) {
        // 当前天日期小于当前月总天数,2月份比较特殊的月份
        dateObj.start = year + "-" + (month - 6) + "-" + (start6MonthDay - (endMonthDay - day));
      } else {
        dateObj.start = year + "-" + (month - 6) + "-" + start6MonthDay;
      }
    } else {
      dateObj.start = year + "-" + (month - 6) + "-" + day;
    }
  }
  const newThreeMonthDate = [dateObj.start, dateObj.end];
  return newThreeMonthDate;
}

// 获取一年
export function getLastOneYearDate() {
  if (month - 12 <= 0) {
    // 如果是1、2、3、4、5、6月，年数往前推一年
    const startOneYearMonthDay = new Date(year - 1, 12 - (12 - parseInt(month)), 0).getDate(); // 6个月前所在月的总天数
    if (startOneYearMonthDay < day) {
      // 6个月前所在月的总天数小于现在的天日期
      dateObj.start = year - 1 + "-" + (12 - (12 - month)) + "-" + startOneYearMonthDay;
    } else {
      dateObj.start = year - 1 + "-" + (12 - (12 - month)) + "-" + day;
    }
  } else {
    const startOneYearMonthDay = new Date(year, parseInt(month) - 12, 0).getDate(); // 6个月前所在月的总天数
    if (startOneYearMonthDay < day) {
      // 一年前所在月的总天数小于现在的天日期
      if (day < endMonthDay) {
        // 当前天日期小于当前月总天数,2月份比较特殊的月份
        dateObj.start = year + "-" + (month - 12) + "-" + (startOneYearMonthDay - (endMonthDay - day));
      } else {
        dateObj.start = year + "-" + (month - 12) + "-" + startOneYearMonthDay;
      }
    } else {
      dateObj.start = year + "-" + (month - 12) + "-" + day;
    }
  }
  const newThreeMonthDate = [dateObj.start, dateObj.end];
  return newThreeMonthDate;
}
